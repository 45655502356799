export const sampleSettings = {
  teamName: 'Sample',
  settings: {
    hasCourses: { display: true, value: 'Cursos' },
    hasEvents: { display: true, value: 'Eventos' },
    hasTools: { display: true, value: 'Ferramentas' },
    hasStore: { display: true, value: 'Loja' },
    hasHistorical: true,
    hasRecaptcha: true,
    hasApplications: {
      display: true,
      value: 'Aplicativos',
      applicationName: 'aplicativo'
    },
    hasUsersEngagement: true,
    displayGenderField: true,
    userFilter: ['Gênero', 'Setor', 'Tags', 'Login', 'Atividade'],
    userTypes: [
      'Administrador',
      'Usuário App',
      'Speaker',
      'Externo',
      'Convidado',
      'PJ',
      'Editor de Loja',
      'Editor de Conteúdo'
    ],
    hasBrands: false,
    hasAcademyRole: true
  }
};

export const teamSettings = [
  {
    teamName: 'grupopardini',
    settings: {
      hasCourses: { display: true, value: 'Você sabia?' },
      hasEvents: { display: true, value: 'Acontece' },
      hasTools: { display: true, value: 'Dia a Dia' },
      hasStore: { display: false },
      hasHistorical: false,
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasUsersEngagement: false,
      displayGenderField: false,
      userTypes: ['Administrador', 'Usuário App', 'Speaker', 'PJ'],
      userFilter: ['Setor', 'Tags', 'Login'],
      hasBrands: true,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'MRN',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Aplicativos' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker', 'Externo'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'youseed',
    settings: {
      hasApplications: {
        display: true,
        value: 'Central de Serviços',
        applicationName: 'aplicativo'
      },
      hasTools: { display: false, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'big',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'ETs',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'FERAs',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Pintou Parceria Suvinil',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: [
        'Administrador',
        'Usuário App',
        'Speaker',
        'Editor de Loja',
        'Editor de Conteúdo'
      ],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login', 'Atividade'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'PetroRio',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'demo',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'ioasys',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Links Úteis' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'hubsocial',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Team',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'programa de estagio',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'noz',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'The Bakery Brasil',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'safers',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Sou SmarttBotter',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Links Úteis' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Time Cibra',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker', 'Convidado'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'astro',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'ligga',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Bosque',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'somosmachado',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Grupo Arizona',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Links Úteis' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Serquip mg',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Voke',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'pelando',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Vedacit',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login', 'Atividade'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Abraselinos',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Aterpa',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Cipa',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'Lwart',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login'],
      hasBrands: false,
      hasAcademyRole: false
    }
  },
  {
    teamName: 'mestreconstrutor',
    settings: {
      hasApplications: {
        display: false,
        value: 'Aplicativos',
        applicationName: 'aplicativo'
      },
      hasTools: { display: true, value: 'Ferramentas' },
      userTypes: ['Administrador', 'Usuário App', 'Speaker'],
      userFilter: ['Gênero', 'Setor', 'Tags', 'Login', 'Profissões'],
      hasBrands: false,
      hasAcademyRole: false
    }
  }
];
